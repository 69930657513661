import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";

import {
  DataFilter,
  DatePicker,
  SelectedDateRangeState,
  SkeletonHeader,
  SkeletonLogo,
} from "@storybook";
import { Json } from "types";
import { getDate, getDateWithTime, getObjectFromUseSearchParams } from "utils";
import { useNetwork } from "hooks";
import { API_URL } from "constant";
import UseActivityApi from "./store/hook";
import { ActivityDataState } from "./store/state";
import { TableEmptyMessage } from "@storybook/react-responsive-table/components";

import "./activity.scss";
import { searchedTextState } from "@storybook/data-filter/state";

export const ActivityLog = () => {
  const { get: getUsers, data: usersList } = useNetwork();

  const [searchParams] = useSearchParams();
  const { fetchActivity, isLoaded  } = UseActivityApi();
  const activityData = useRecoilValue(ActivityDataState);
  const selectedDateRange = useRecoilValue<any>(SelectedDateRangeState);
  const setSearchedText = useSetRecoilState(searchedTextState);

  const initialFilter = {
    limit: 20,
    offset: 0,
  };

  const [updateNewData, SetUpdateNewData] = useState(false);
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });

  const AUCTION_FILTERS = useMemo(() => {
    return [
      {
        header: "Users",
        key: "users",
        options:
          usersList?.data?.length > 0
            ? usersList?.data?.map((user: any) => {
                const { firstName, lastName, _id } = user;
                const name = `${firstName}  ${lastName}`;
                return { label: name, value: _id };
              })
            : [],
      },
      {
        header: "Actions",
        key: "actions",
        options: [
          { label: "Locked", value: "locked" },
          { label: "Unlocked", value: "unlocked" },
          { label: "Invited", value: "invited" },
          { label: "Joined", value: "joined" },
          { label: "Created", value: "created" },
          { label: "Deleted", value: "deleted" },
          { label: "Edited", value: "edited" },
          { label: "Removed", value: "removed" },
          { label: "Blocked", value: "blocked" },
          { label: "Unblocked", value: "unblocked" },
        ],
      },
    ];
  }, [usersList]);

  const handleFilter = (filterObj: Json) => {
    SetUpdateNewData(false);
    setFilters((prev) => ({ ...prev, ...filterObj }));
  };

  const onReset = () => {
    SetUpdateNewData(false);
    setFilters({ ...initialFilter });
    setSearchedText("");
  };

  useEffect(() => {
    getUsers(API_URL.USER_LIST);
  }, []);

  useEffect(() => {
    SetUpdateNewData(false);
    setFilters({ ...filters, ...initialFilter });
  }, [selectedDateRange]);

  useEffect(() => {
    const options = { updateNewData };
    const query = {
      ...filters,
      to: getDate(selectedDateRange[0].startDate),
      from: getDate(selectedDateRange[0].endDate),
    };
    fetchActivity(query, options);
  }, [filters, selectedDateRange]);

  const actionText = useCallback((actionItem: any) => {
    const { action, actionBy, role, actionFor, updatedAt, metadata } = actionItem;

    const activityDt = getDateWithTime(updatedAt);
    function formatDate(date: Date) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
    
      if (date.toDateString() === today.toDateString()) {
        return `Today at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase()}`;
      } else if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',  hour12: true}).toUpperCase()}`;
      } else {
        return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short',  hour12: true }).toLocaleUpperCase();
      }
    }
    switch (action) {
      case "joined":
        return (
          <div>
            {actionBy?.name} has <span className="action">joined</span> for the
            role named{" "}
            <span style={{ fontWeight: "bold" }}>{role?.current?.name}</span>
            <div className="activity-date">
              {formatDate(new Date(activityDt))}{" "}
            </div>
          </div>
        );

      case "invited":
        return (
          <div>
            {actionBy?.name} <span className="action">invited</span>{" "}
            {actionFor?.name} for the role named{" "}
            <span style={{ fontWeight: "bold" }}>“{role?.current?.name}”</span>.
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
      case "created":
        return (
          <div>
            {actionBy?.name} has <span className="action">created</span> a new
            role named{" "}
            <span style={{ fontWeight: "bold" }}>“{role?.current?.name}”</span>.
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
      case "deleted":
        return (
          <div>
            {actionBy?.name} has <span className="action">deleted</span> role
            named{" "}
            <span style={{ fontWeight: "bold" }}>“{role?.current?.name}”</span>.
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
      case "edited":
        return (
          <div>
            {actionBy?.name} has <span className="action">edited</span> the
            <span style={{ fontWeight: "bold", padding: "4px" }}>
              “{role?.current?.name}”{" "}
            </span>
            role.
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
      case "updated":
        return (
          <div className="p-8">
            {actionBy?.name} has set{" "}
            <span className="action">trading fees</span> and{" "}
            <span className="action">lockup period</span> for asset{" "}
            {metadata?.issuerName}.
            <span style={{ fontWeight: "bold" }}>{metadata?.symbol}.</span>
            <div className="activity-type-details">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Primary trade fee</th>
                    <th>Secondary trade fee</th>
                    <th>Lockup period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{metadata?.body?.primaryTrade?.sell}%</td>
                    <td>{metadata?.body?.secondaryTrade?.sell}%</td>
                    <td>{metadata?.body?.lockupPeriod?.duration} {metadata?.body?.lockupPeriod?.unit} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
      default:
        return (
          <div>
            {actionBy?.name} <span className="action">{action}</span>{" "}
            {actionFor?.name}’s account.
            <div className="activity-date">
              {formatDate(new Date(activityDt))}
            </div>
          </div>
        );
    }
  }, []);

  const actionIcon = useCallback((action: string) => {
    switch (action) {
      case "locked":
      case "blocked":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-lock-2-line"></i>
          </div>
        );
      case "unlocked":
      case "unblock":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-lock-unlock-line"></i>
          </div>
        );
      case "invited":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-user-add-line"></i>
          </div>
        );
      case "joined":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-user-line"></i>
          </div>
        );
      case "created":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-user-star-line"></i>
          </div>
        );
      case "deleted":
      case "removed":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-user-unfollow-line"></i>
          </div>
        );
      case "edited":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-pencil-line"></i>
          </div>
        );
      case "updated":
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-exchange-line"></i>
          </div>
        );
      default:
        return (
          <div className={"activity-icon " + action}>
            <i className="ri-lock-2-line"></i>
          </div>
        );
    }
  }, []);
  const handleFetchMore = useCallback(() => {
    SetUpdateNewData(true);
    setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
  }, []);

  return (
    <div className="activity-log ">
      <div className="activity-log__calender-btn"> 
      <div className="activity-log_header">List of Activity</div>
      <div className="activity-log_wrapper">
        <DataFilter
          selectedValue={filters}
          data={AUCTION_FILTERS}
          position="right"
          onChangeFilter={(obj) => handleFilter({ ...obj, offset: 0 })}
          onClickReset={onReset}
          isLoading={!isLoaded}
        />
        <DatePicker />
        </div>
      </div>
      {activityData?.loading && activityData?.data?.length === 0 ? (
        <div className="activity-skelton">
          {Array(6)
            .fill(1)
            .map((card: any) => (
              <div className="activity--skelton" key={card}>
                <SkeletonLogo />
                <SkeletonHeader />
              </div>
            ))}
        </div>
      ) : (
        <>
          {activityData?.data?.length ? (
            <>
              <div
                id="scrollableDiv"
                style={{
                  height: "78vh",
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  className="activity-item-gap"
                  dataLength={activityData?.data?.length || 0}
                  next={handleFetchMore}
                  hasMore={
                    activityData?.data?.length ===
                    filters.offset * filters.limit + filters.limit
                  }
                  loader={""}
                  scrollableTarget="scrollableDiv"
                >
                  {activityData?.data?.map((item: any) => {
                    return (
                      <div className="activity--log">
                        <div className="activity-row">
                          <div className="activity-left">
                            {actionIcon(item?.action)}
                            <div className="activity-line"></div>
                          </div>
                          {actionText(item)}
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
                {activityData?.loading && (
                  <div className="activity-skelton load-more">
                    {Array(6)
                      .fill(1)
                      .map((card: any) => (
                        <div className="activity--skelton" key={card}>
                          <SkeletonLogo />
                          <SkeletonHeader />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <TableEmptyMessage height={"calc(100vh - 400px)"} />
          )}
        </>
      )}
    </div>
  );
};
