import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useSearchParams } from "react-router-dom";

import { Private } from "./privates";
import { initalFilterStaate } from "./store";
import { getObjectFromUseSearchParams } from "utils";
import {ASSETS_STATUS} from  "./store/constant";

export const MintAsset = (props:any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterObj = getObjectFromUseSearchParams(searchParams);

  const [activeTab, setActiveTab] = useState(filterObj.status === "APPROVED" ? 2 : 1);
  const [initialfiter,setInitialfilter] = useRecoilState(initalFilterStaate);


  useEffect(() => {
    if (props?.type) {
      setInitialfilter((prev: any) => ({ ...prev, type: props.type }));
    
    }
  }, [ props.type]);

  const handleTabClick = useCallback((tabNumber: React.SetStateAction<number>) => {
     const { immediate, ..._filters } = initialfiter;
    if (tabNumber === 2) {
      setSearchParams({ ..._filters, status: ASSETS_STATUS.APPROVED });
    } else {
       setSearchParams({
         ..._filters,
         status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
       });
    }
    setActiveTab(tabNumber);
  },[initialfiter]);

  return (
    <>
      <div className="minted-asset">
        <div className="tabs">
          <div
            className={`tab revenue-chart__label ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Approval Pending
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            Listed
          </div>
        </div>

        <div className="tab-content">
          {activeTab === 1 && <Private  type={props.type} />}
          {activeTab === 2 && (
            <>
              <Private type={props.type}/>
            </>
          )}
        </div>
      </div>
    </>
  );
};
