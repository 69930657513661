import React from "react";
import "./progressLine.scss";
import { useCurrency } from "hooks";

type ProgressLineProps = {
  progress: number; 
  minRaise: number; 
  targetRaise: number; 
};

const ProgressLine: React.FC<ProgressLineProps> = ({ progress, minRaise, targetRaise }) => {
    const {
    formatCurrencyWithBillion
  } = useCurrency();
  const validTargetRaise = targetRaise > 0 ? targetRaise : 1;

  const progressPercentage = progress === 0 ? 0 : progress === Number(minRaise) ? 50 : progress > Number(minRaise) ? 50 + (progress / validTargetRaise) * 100 : 50 - (progress / validTargetRaise) * 100;

  const clampedProgress = progress >= minRaise ? progress : 0; 

  return (
    <div className="progress-container">
      <div className="progress-bar-wrapper">
        <div
          className="min-raise-indicator"
          style={{
            left: `${50}%`,
          }}
        ></div>
        <div
          className={`progress-bar ${progress >= minRaise ? "progress-bar-green" : "progress-bar-red"}`}
          style={{
            width: `${progressPercentage}%`, 
          }}
          role="progressbar"
          aria-valuenow={clampedProgress} 
          aria-valuemin={0}
          aria-valuemax={validTargetRaise}
        ></div>
        <div className="progress-background"></div>
      </div>
      <div className="amount-labels">
        <div>$0</div>
        <div>{`${formatCurrencyWithBillion(minRaise, 2)}`}</div>
        <div>{`${formatCurrencyWithBillion(targetRaise, 2)}`}</div>
      </div>
    </div>
  );
};

export default ProgressLine;
