import { useCallback } from "react";

import "./sba7-details.scss";
import {
  DataRoomFiles,
  SkeletonLogo,
  SkeletonTable,
} from "@storybook";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useCurrency } from "hooks";
import { Json } from "types";
import { Sba7LoanTables } from "./Sba7LoanTables";
import { useUserPermission } from "hooks/user-permission";

import { getDateWithTime } from "utils";
import { ASSET_TYPE_TEXT, LOAN_NAME } from "../store/constant";
import { SBA7A } from "layouts";
import { APP_ROUTES } from "views/routes";


export const Sba7Details = ({
  selectedAsset,
  handeCloseApproval,
  handelReject,
  handelApprove,
}: any) => {
  const buttonDisable = useUserPermission();
  const {
    formatCurrencyWithBillion,
    amountToBondPoints,
    formatNumberWithBillion,
  } = useCurrency();
  const sba7Details: any = selectedAsset || {};
  const isLoaded = true;

  const assetTpye =
  window.location.href.indexOf(APP_ROUTES.MANU_HOME_LOANS) !== -1
    ? ASSET_TYPE_TEXT.MHL : window.location.href.indexOf(APP_ROUTES.EQUIPMENT_FINANCE) !== -1
    ? ASSET_TYPE_TEXT.EF
    : SBA7A;

  const dataRoomFilterFiles = sba7Details?.dataRoom?.filter(
    (item: any) => item?.uploadType === null
  );
  
  const sbaAndNormalPrice = useCallback(
    (options: Json) => {
      const { price = 0, brake = false, labelClass = "" } = options || {};
      const bp = amountToBondPoints(sba7Details?.currentLoanBalance, price);
      return (
        <>
          {formatNumberWithBillion(bp, 6)}
          {brake && <br />}{" "}
          <small className={`text-lower ${labelClass}`}>
            {Number(bp) > 1 ? "bond points" : "bond point"}
          </small>
        </>
      );
    },
    [sba7Details]
  );

  const handelApproveAction = useCallback(
    (status: string, e: any) => {
      if (status === "APPROVED") {
        handelApprove(e, selectedAsset);
      } else {
        handelReject(e, selectedAsset);
      }
      handeCloseApproval();
    },
    [handeCloseApproval, handelApprove, handelReject, selectedAsset]
  );

  return (
    <>
      <div className="sba7-details-container">
        <h1 className="sba7-details-heading">
          {assetTpye}
          <p className="sba7-details-desc">
            {assetTpye} details for approved/denied request
          </p>
        </h1>

        <div className="sba7-details-image">
          {!!sba7Details?.coverImage ? (
            <img src={sba7Details?.coverImage} alt="Sba7detailimage" />
          ) : (
            <i className="ri-image-add-line" />
          )}
        </div>

        <div className="sba7-details">
          <div className="sba7-details-and-logo">
            {!isLoaded && <SkeletonLogo className="logo-img" />}
            {isLoaded && (
              <div
                className={`logo-img ${!sba7Details?.logo ? "with-bg" : ""}`}
              >
                {!!sba7Details?.logo && (
                  <img src={sba7Details?.logo} alt="logo" />
                )}
                {!sba7Details?.logo && <i className="ri-file-upload-line" />}
              </div>
            )}
            {!isLoaded && (
              <SkeletonTable
                listsToRender={2}
                numberColumn={1}
                className="border-b-0"
              />
            )}
            {isLoaded && (
              <div className="title-heading-symbol">
                <h2 className="sba7-name" data-tooltip-id={sba7Details.id}>
                  {sba7Details?.name}
                </h2>
                {sba7Details?.name?.length >= 32 && (
                  <ReactTooltip
                    className="theme-tooltip"
                    id={sba7Details?.id}
                    place="top"
                    content={sba7Details?.name}
                  />
                )}
                <span className="title-symbol">{sba7Details?.symbol}</span>
              </div>
            )}
          </div>
          {isLoaded && (
            <p className="sba7-desc">{sba7Details?.description || "--"}</p>
          )}
          {!isLoaded && (
            <SkeletonTable
              listsToRender={1}
              numberColumn={1}
              className="border-b-0 sba7-desc"
            />
          )}
        </div>

        <div className="sba7-detail-stats">
          <h2 className="sba7-details-heading">
            Loan Information
            <p className="sba7-details-desc">
              Discover detailed information specific to {assetTpye} loans,
              providing key data for easy reference.
            </p>
          </h2>
          {!isLoaded && (
            <SkeletonTable
              listsToRender={3}
              numberColumn={3}
              className="border-b-0"
            />
          )}
          {isLoaded && (
            <div className="stats-details">
              <div className="stats-item">
                <p className="stat-label">Categories</p>
                <p className="stat-value">{"Loans" || "--"}</p>
              </div>
              <div className="stats-item">
                <p className="stat-label">Listing type</p>
                <p className="stat-value">
                  {sba7Details?.sbaType?.replaceAll("_", " ")?.toLowerCase() ||
                    "--"}
                </p>
              </div>
              <div className="stats-item">
                <p className="stat-label">Name</p>
                <p className="stat-value">
                  {sba7Details?.issuerDetails?.firstName +
                    " " +
                    sba7Details?.issuerDetails?.lastName || "--"}
                </p>
              </div>

              <div className="stats-item">
                <p className="stat-label">Date & time</p>
                <p className="stat-value">
                  {sba7Details?.createdAt
                    ? getDateWithTime(
                        sba7Details?.createdAt,
                        "MMM dd, yyyy, hh:mm a"
                      )
                    : "--"}
                </p>
              </div>
              <div className="stats-item">
                <p className="stat-label">No. of individual loans</p>
                <p className="stat-value">
                  {sba7Details?.numberOfLoans || "--"}
                </p>
              </div>
             {(sba7Details.type === "sba7" && <div className="stats-item">
                <p className="stat-label">Orderbook params</p>
                <p className="stat-value">
                  {LOAN_NAME[sba7Details?.loanType] || "--"}
                </p>
              </div>)}
              <div className="stats-item">
                <p className="stat-label">Current Loan Balance</p>
                <p className="stat-value">
                  {formatCurrencyWithBillion(
                    sba7Details?.currentLoanBalance,
                    2
                  )}
                </p>
              </div>
              <div className="stats-item">
                <p className="stat-label">Weighted Average Yield at Par</p>
                <p className="stat-value">
                  {(sba7Details?.currentYield || 0)?.toFixed(6)}%
                </p>
              </div>
              {sba7Details?.sbaType !== "ORDERBOOK_SERIES" && (
                <div className="stats-item">
                  <p className="stat-label">Asking price</p>
                  <p className="stat-value">
                    {sbaAndNormalPrice({
                      price: sba7Details?.price,
                    })}
                  </p>
                  <p className="stat-label">
                    {formatCurrencyWithBillion(sba7Details?.price, 2, true)}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <Sba7LoanTables
          sba7Details={{
            bidSheetJSON: sba7Details?.bidSheetJSON || [],
            sba1502JSON: sba7Details?.sba1502JSON || [],
          }}
        />

        <hr className="middle-divider" />
        <DataRoomFiles
          dataRoomFiles={dataRoomFilterFiles || []}
          loading={!isLoaded}
        />

        {selectedAsset?.status === "PENDING" && (
          <div className="action-btns">
            <button
              className={`reject ${buttonDisable && "button--disabled"}`}
              onClick={(e) => handelApproveAction("REJECTED", e)}
              disabled={buttonDisable}
            >
              Reject
            </button>
            <button
              className={`approve ${buttonDisable && "button--disabled"}`}
              onClick={(e) => handelApproveAction("APPROVED", e)}
              disabled={buttonDisable}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default Sba7Details;
