export const MINT_TABLE_HEADER = [
  {
    label: "Name/Symbol ",
    key: "name",
    format: "jsx",
    className: "",
    width: "20%",
  },
  {
    label: "Creators",
    key: "createdBy",
    format: "jsx",
    className: "",
    width: "10%",
  },
  {
    label: "Files",
    key: "supportingDoc",
    format: "jsx",
    width: "10%",
  },
  {
    label: "Memo",
    key: "memo",
    format: "jsx",
    width: "10%",
  },
  {
    label: "Max Supply",
    key: "maxSupply",
    format: "jsx",
    width: "11%",
  },
  {
    label: "Price",
    key: "price",
    format: "jsx",
    width: "10%",
  },
  {
    label: "Total Value",
    key: "totalValue",
    format: "jsx",
    width: "12%",
  },

  {
    label: "Actions",
    key: "actions",
    format: "jsx",
    width: "20%",
  },
];

export const FILE_EXTENSION_URL: any = {
  pdf: "https://storage.googleapis.com/satschel-assets-public/assets/logo/pdf-logo-c001.svg",
  doc: "https://storage.googleapis.com/satschel-assets-public/assets/logo/doc-logo-c001.svg",
  docx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/docx-logo-c001.svg",
  csv: "https://storage.googleapis.com/satschel-assets-public/assets/logo/csv-logo-c001.svg",
  ppt: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DPPT.png",
  pptx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DPPT.png",
  xls: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLS.png",
  xlsx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLSX.png",
  png: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1PNG.png",
  jpeg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPEG.png",
  jpg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPG.png",
};
// AUCITON NAME -STRING
// CREATED BY - STRING
// START PRICE - STRING
// START DATE AND TIME - STRING
// AUCTION ENDS IN  -- JSX
// SUPPORTING DOCUMENTS -- JSX
// ACTIONS ---JSX

export const ASSETS_STATUS = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};